import request from '@/utils/requestV2'
const qs = require('qs')

// 获取公司角色
export function getWorkRolesByCompany (data) {
  return request({
    url: '/ooh-os/v3/role/getrolesbycompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
