import request from '@/utils/requestV2'
const qs = require('qs')

// 获取用户绑定详情
export function getuseropendetail (data) {
  return request({
    url: '/ooh-os/v1/useropen/getuseropendetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
