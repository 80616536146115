<template>
  <Modal v-model="bindModal" :mask-closable="false" :footer-hide="true">
    <p slot="header" style="text-align:center">
      <span>用户F码绑定详情</span>
    </p>
    <Table :data="list" :columns="titles" stripe></Table>
  </Modal>
</template>

<script>
import { getuseropendetail } from '@/api/os/useropen'
import { unbindqyuser } from '@/api/os/user'

export default {
  name: 'ResetPwdComponent',
  data () {
    return ({
      bindModal: false,
      loading: false,
      userId: 0,

      list: [],
      titles: [
        { title: '绑定对象', key: 'appName', align: 'center' },
        { title: '绑定用户名', key: 'openId', align: 'center' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    const that = this
                    that.$Modal.confirm({
                      title: '操作确认',
                      content: '<p>是否要确认解除此用户的' + params.row.appName + '绑定关系？</p>',
                      loading: true,
                      onOk: () => {
                        const query = {
                          appid: params.row.appId,
                          userId: that.userId
                        }
                        unbindqyuser(query).then((res) => {
                          if (res && !res.errcode) {
                            that.$Notice.success({ desc: '解绑成功' })
                            that.$store.commit('set_userManage_update', new Date())
                            that.inituseropendetail()
                          }
                          that.$Modal.remove()
                        }).catch(() => {
                          that.$Modal.remove()
                        })
                      }
                    })
                  }
                }
              }, '解绑')
            ])
          }
        }
      ]
    })
  },
  methods: {
    showModal (userId) {
      this.userId = userId
      this.inituseropendetail()
      this.bindModal = true
    },
    inituseropendetail () {
      getuseropendetail({ userId: this.userId }).then(res => {
        this.list = res
      })
    }
  }
}
</script>
