<template>
  <div>
    <Row :gutter="8" class="p-b-10">
      <i-col span="12">
        <Input size="small" v-model.trim="query.keyword" placeholder="关键词" />
      </i-col>
      <i-col span="12">
        <Button  size="small" type="primary" icon="ios-search" @click="handleSearch">搜索</Button>
        <Button  size="small" type="success" icon="md-person-add" class="m-l-5" @click="handleCreateUser">新增用户</Button>
      </i-col>
    </Row>

    <Table size="small" :data="list" :columns="titles" stripe></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="changePage"></Page>
    </div>

    <user-modify-component ref="userModify"></user-modify-component>
    <reset-pwd-component ref="resetPwd"></reset-pwd-component>
    <BindFcodeComponent  ref="bindInfo"></BindFcodeComponent>
  </div>
</template>

<script>
import { getUserPage, deleteUser } from '@/api/os/user'

import { formatEnable } from '@/utils/tagStatus'
import UserModifyComponent from './UserModify'
import ResetPwdComponent from './ResetPwd'
import BindFcodeComponent from './BindFcode'

export default {
  name: 'UserPageComponent',
  components: {
    UserModifyComponent, ResetPwdComponent, BindFcodeComponent
  },
  data () {
    return {
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        isPlatform: false,
        companyId: this.$store.getters.token.userInfo.companyId,
        keyword: ''
      },
      list: [],
      titles: [
        { title: '编号', key: 'id', width: 100, align: 'center' },
        { title: '用户名', key: 'username', width: 150, align: 'center' },
        { title: '姓名', key: 'name', width: 120, align: 'center' },
        // {
        //   title: '企业微信ID',
        //   align: 'center',
        //   render: (h, params) => {
        //     if (params.row.qyuserId !== '') {
        //       return h('span', {}, params.row.qyuserId)
        //     } else {
        //       return h('span', { style: { color: '#ef4f4f' } }, params.row.fcode + ' (未绑定)')
        //     }
        //   }
        // },
        {
          title: 'F码',
          align: 'center',
          width: 250,
          render: (h, params) => {
            return h('div', [
              h('span', { }, params.row.fcode),
              h('a', {
                on: {
                  click: () => {
                    this.$refs.bindInfo.showModal(params.row.id)
                  }
                }
              }, '（绑定详情）')
            ])
          }
        },
        {
          title: '是否可用',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return formatEnable(h, params.row.enabled)
          }
        },
        {
          title: '角色权限',
          align: 'center',
          render: (h, parmas) => {
            const workRoles = []
            if (parmas.row.stationRole) {
              parmas.row.stationRole.forEach(item => {
                workRoles.push(h('Tag', {
                  // props: {
                  //   color: 'cyan'
                  // }
                }, item.name))
              })
            }
            return h('div', workRoles)
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$refs.resetPwd.showModal(params.row.id)
                  }
                }
              }, '重置密码'),
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleEditUser(params)
                  }
                }
              }, '编辑'),
              h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.delete(params)
                  }
                }
              }, '删除')
            ])
          }
        }
      ]
    }
  },
  methods: {
    getPageData () {
      getUserPage(this.query).then(response => {
        this.total = response.totalRow
        this.list = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    reloadData () {
      this.query.pageNumber = 1
      this.getPageData()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.getPageData()
    },
    handleCreateUser () {
      this.$refs.userModify.showModal()
    },
    handleEditUser (param) {
      this.$refs.userModify.showModal(param.row)
    },
    delete (para) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '<p>确定要删除该用户: ' + para.row.username + '(' + para.row.name + ')，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          deleteUser({ userId: para.row.id }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '删除成功！' })
              this.list.splice(para.index, 1)
            }
            this.$Modal.remove()
          }).catch(error => {
            this.$Notice.error({ title: '服务器请求错误', desc: error })
          })
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getPageData()
    }
  },
  created () {
    this.getPageData()
  },
  computed: {
    beginUpdate () {
      return this.$store.state.userManage.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.reloadData()
    }
  }
}
</script>
