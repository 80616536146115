<template>
  <Modal v-model="resetModal" :mask-closable="false">
    <p slot="header" style="text-align:center">
      <span>重置用户密码</span>
    </p>
    <Form ref="userModel" :model="userModel" :rules="ruleValidate" :label-width="60">
      <input type="hidden" v-model="userModel.id" />
      <FormItem label="新密码" prop="newPwd">
        <i-input type="password" v-model.trim="userModel.newpwd"></i-input>
      </FormItem>

    </Form>
    <div slot="footer">
      <Button class="ivu-btn ivu-btn-text" @click="resetModal=false">
        <span>取消</span>
      </Button>
      <Button type="primary" :loading="loading" @click="asyncEdit('userModel')">
        <span>确定</span>
      </Button>
    </div>

  </Modal>
</template>

<script>
import { resetPassword } from '@/api/os/user'
export default {
  name: 'ResetPwdComponent',
  data () {
    return ({
      resetModal: this.resetPwd,
      loading: false,
      userModel: {
        id: 0,
        newpwd: ''
      },
      ruleValidate: {
        newpwd: [
          { required: true, message: '新密码不能为空。', trigger: 'blur' }
        ]
      }
    })
  },
  methods: {
    showModal (userId) {
      this.userModel.id = userId
      this.resetModal = true
    },
    asyncEdit (name) {
      this.loading = true
      this.$refs[name].validate((valid) => {
        if (valid) {
          resetPassword(this.userModel).then(res => {
            if (res) {
              this.loading = false
              this.resetModal = false
              this.$Notice.success({ desc: '重置密码成功！' })
            } else {
              this.$Notice.error({ desc: '重置密码失败！' })
            }
          }).catch(error => {
            this.$Notice.error({ desc: error.errmsg })
          })
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>
