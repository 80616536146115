<template>
  <Modal v-model="modifyUserModal" :mask-closable="false" width="1000">
    <p slot="header" class="text-center" :style="{'color': isEdit?'#ee7c4b':''}">
        <Icon type="ios-information-circle" class="m-r-5"></Icon>
        <span>{{isEdit?'编辑用户':'新增用户'}}</span>
    </p>

    <Form
      ref="userData"
      :model="userData"
      :rules="ruleValidate"
      label-position="top"
    >
      <Row :gutter="16">
        <i-col span="12">
          <FormItem
            label="用户账号"
            prop="username"
          >
            <i-input size="small" v-model="userData.username"></i-input>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem
            v-if="!isEdit"
            label="密码"
            prop="password"
          >
            <i-input size="small" v-model="userData.password"></i-input>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="16">
        <i-col span="12">
          <FormItem
            label="用户姓名"
            prop="name"
          >
            <i-input size="small" v-model="userData.name"></i-input>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem
            label="移动电话"
            prop="mobile"
          >
            <i-input size="small" v-model="userData.mobile"></i-input>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="16">
        <i-col span="24">
          <FormItem
            label="电子邮箱"
            prop="email"
          >
            <i-input size="small" v-model="userData.email"></i-input>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem
            label="启用"
            prop="enabled"
          >
            <i-switch v-model="userData.enabled" true-color="#4285F4" false-color="#AEAEAE ">
              <span slot="open">开</span>
              <span slot="close">关</span>
            </i-switch>
          </FormItem>
        </i-col>
      </Row>

      <FormItem
        label="用户角色"
        prop="workStationRoleArr"
      >
        <CheckboxGroup v-model="userData.workStationRoleArr">
          <div v-for="(product,index) in productArray" :key="index">
            <!-- 根据项目情况来分组，如果存在数据，那么勾选，否则不显示 -->
            <template v-if="publisherWorkRoleIds.filter(x=>x.product===product.id).length>0">
              <p class="text-orange">{{ product.name }}</p>
              <Checkbox v-for="role in publisherWorkRoleIds.filter(x=>x.product===product.id).sort((a, b) => { return a.parentId - b.parentId })" :key="role.id" :label="role.id">
                {{role.name}}
                <Tag color="warning" v-if="role.parentId===1" style="margin: 0px;padding: 0px;height: 12px;line-height: 12px;">官</Tag>
              </Checkbox>
            </template>
          </div>
        </CheckboxGroup>
      </FormItem>
    </Form>

    <div slot="footer">
      <Button class="ivu-btn ivu-btn-text ivu-btn-large" @click="modifyUserModal=false">
        <span>取消</span>
      </Button>
      <Button class="ivu-btn ivu-btn-primary ivu-btn-large" :loading="loading" @click="handleSubmit('userData')">
        <span>确定</span>
      </Button>
    </div>
  </Modal>
</template>

<script>
import { addUser, updateUser } from '@/api/os/user'
import { getWorkRolesByCompany } from '@/api/os/roleV3'
import { dictionary } from '@/api/sys/dictbiz'

export default {
  data () {
    return ({
      modifyUserModal: false,
      isEdit: false,

      loading: false,
      publisherWorkRoleIds: [], // 角色权限清单
      productArray: [],
      workStationCheckRoles: [], // 勾选的用户权限清单

      userData: {
        // id: 0,
        roleIds: '[]',
        workStationRoleIds: '',
        workStationRoleArr: [],
        username: '',
        password: '',
        name: '',
        mobile: '',
        email: '',
        enabled: true,
        companyId: this.$store.getters.token.userInfo.companyId
      },
      ruleValidate: {
        username: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        password: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        email: [{ type: 'email', message: ' ', trigger: 'blur' }],
        mobile: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        workStationRoleArr: [
          {
            required: true,
            type: 'array',
            min: 1,
            message: '至少选择一个用户角色',
            trigger: 'change'
          }
        ]
      }
    })
  },
  mounted () {
    this.iniBaseData()
  },
  methods: {
    showModal (data) {
      this.isEdit = !!data
      this.modifyUserModal = true
      this.initUserRoleInfo(data)
    },
    iniBaseData () {
      // 获取工作台角色清单
      const query = { companyId: this.$store.getters.token.userInfo.companyId }
      getWorkRolesByCompany(query).then((res) => {
        this.publisherWorkRoleIds = res
      })

      // 获取所有的项目列表
      dictionary({ code: 'role_product' }).then(res => {
        this.productArray = res.data.filter(x => x.dictKey !== '5').map(x => {
          return {
            id: parseInt(x.dictKey),
            name: x.dictValue
          }
        })
      })
    },
    initUserRoleInfo (userInfo) {
      this.userData = {
        roleIds: '[]',
        workStationRoleIds: '',
        workStationRoleArr: [],
        username: '',
        password: '',
        name: '',
        mobile: '',
        email: '',
        enabled: true,
        companyId: this.companyId
      }

      if (userInfo) { // 编辑用户
        this.userData.id = userInfo.id
        this.userData.username = userInfo.username
        this.userData.mobile = userInfo.mobile
        this.userData.name = userInfo.name
        this.userData.email = userInfo.email
        this.userData.enabled = userInfo.enabled
        // 设置角色权限反选
        this.userData.workStationRoleArr = userInfo.stationRole.map(x => x.id)
      }
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true

          const formatCheckRoles = []
          let workRole = null
          this.userData.workStationRoleArr.forEach(element => {
            workRole = this.publisherWorkRoleIds.find(x => x.id === element)
            if (workRole) {
              formatCheckRoles.push({
                publisherId: workRole.companyId,
                roleId: element,
                userId: this.userData.id || null
              })
            }
          })
          this.userData.workStationRoleIds = JSON.stringify(formatCheckRoles)

          if (this.isEdit) {
            updateUser(this.userData).then(res => {
              this.modifyUserModal = false
              this.$Notice.success({ desc: '编辑用户成功！' })
              this.$store.commit('set_userManage_update', new Date())
            }).catch(error => {
              this.$Notice.error({ desc: error.errmsg })
            })
          } else {
            addUser(this.userData).then(res => {
              this.modifyUserModal = false
              this.$Notice.success({ desc: '新建用户成功！' })
              this.$store.commit('set_userManage_update', new Date())
            }).catch(error => {
              this.$Notice.error({ desc: error })
            })
          }

          this.loading = false
        }
      })
    }
  }
}
</script>
